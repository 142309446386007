import {Controller} from "stimulus"
import AutoNumeric from "autonumeric"
import {validateFields, formSubmittable} from "../../../lib/assets/form-validation";
import SlimSelect from "slim-select";
import Rails from "@rails/ujs";

export default class extends Controller {

    connect() {
        console.log("log from permitting-application controller");
        if (document.querySelector(".permitting-form") != null) {
            formSubmittable(document.querySelector(".permitting-form"));
        }
        if (document.querySelector(".application-parcels") != null) {
            Array.from(document.querySelector(".application-parcels").querySelectorAll(".delete-action")).map(action => {
                action.classList.remove("hidden");
            });
        }

    }

    updateFormFields(e) {
        const form = e.target.closest('form');
        const appTemplateId = e.target.value
        const fieldList = form.querySelector(".field-scope");
        let link = 'update_fields?permitting_app_template_id=' + appTemplateId
        if (window.location.pathname.includes("add_child")) {
            link = '/permitting/applications/new/update_fields?permitting_app_template_id=' + appTemplateId
        }
        link += "&field_scope=" + fieldList.value;
        console.log("updating form to app Template" + appTemplateId)
        fetch(link)
            .then(response => {
                return response.text();
            })
            .then(html => {
                document.getElementById("template-specific-fields").innerHTML = html;
            })
        validatePermittingForm(form)
    }


    validateForm(e) {
        const form = e.target.closest('form');
        validatePermittingForm(form)
    }

    updateSelectedParcels(e) {
        if (!(e.target.closest('li').classList.contains('selected'))) {
            console.log('updating selected parcels!')
            const parcelSummaryArea = document.querySelector(".application-parcels").querySelector("ul");
            const chosenParcel = e.currentTarget;
            e.currentTarget.querySelector(".delete-action").classList.remove("hidden");
            chosenParcel.classList.add('selected');
            parcelSummaryArea.appendChild(chosenParcel);
        }
    }

    setParcelAlternateAddress(e) {
        // const form = event.target.closest('form');
        // Rails.fire(form, 'submit');
        const form = event.target.closest('form');
        const formData = new FormData(form);

        fetch(form.action, {
            method: form.method,
            body: formData,
            headers: {
                'Accept': 'application/json'
            }
        })
            .then(response => response.text())
            .then(data => {
                console.log('alternate address updated')
                document.querySelector(".selected-parcel").innerHTML = data;
            })
            .catch(error => console.error('Error:', error));

    }

    updateApplicationTypes(e) {
        const form = e.target.closest('form');
        let jurisdictionId = undefined;
        if (document.querySelector(".application-parcels").querySelector("li") != null) {
            jurisdictionId = document.querySelector(".application-parcels").querySelector("li").dataset.jurisdictionId;
        } else {
            jurisdictionId = e.target.dataset.jurisdictionId;
        }
        console.log("updating application types for jurisdiction " + jurisdictionId);
        const applicationTypeIdValue = document.getElementById("permitting_application_permitting_app_template_id").value
        const regex = /\d*/
        const formAction = form.action
        const applicationId = formAction.match(regex)[0]
        let permitIndustry = undefined;
        if (document.querySelector('input[name="industry"]:checked') != null) {
            permitIndustry = document.querySelector('input[name="industry"]:checked').value
        }
        console.log("updating application type for jurisdiction " + jurisdictionId + " and industry " + permitIndustry);
        const link = 'select_control?jurisdiction_id=' + jurisdictionId + "&application_id=" + applicationId + "&permit_industry=" + permitIndustry
        fetch(link)
            .then(response => {
                return response.text();
            })
            .then(html => {
                document.querySelector(".application-template-selector").innerHTML = html;
                if (applicationTypeIdValue.length > 0) {
                    document.getElementById("permitting_application_permitting_app_template_id").value = applicationTypeIdValue;
                }
                validatePermittingForm(form);
            })

    }

    removeParcel(e) {
        let deleteConfirmed = confirm("Are you sure you want to remove this parcel from the application?");
        if (deleteConfirmed) {
            const parcelSummaryArea = document.querySelector(".application-parcels").querySelector("ul");
            const chosenParcel = e.currentTarget.closest('li');
            const parcelId = chosenParcel.id;
            const parcelFields = Array.from(document.querySelector(".selected-parcels").querySelector(".results-list").querySelectorAll("[data-location-point-id='" + parcelId + "']"))
            chosenParcel.classList.remove('selected');
            e.currentTarget.classList.add('hidden');
            parcelSummaryArea.removeChild(chosenParcel);
            parcelFields.map(field => {
                field.value = 1;
            })
        }
    }

    removeCase(e) {
        e.target.closest('.deletable').remove;
    }

    removeInspection(e) {
        e.target.parentElement.parentElement.remove();
    }

    setParent(e) {
        e.preventDefault();
        console.log('setting parent');
        window.location.href = window.location.href + '/set_parent?parent_id=' + e.target.closest('.result-permitting-application').id;
    }
}

function validatePermittingForm(form) {
    validateFields(form);
    formSubmittable(form);
    let contactIdPresent = true
    if (document.getElementById("validation-messages").childElementCount > 0) {
        if (form.querySelector("#contact_id") != null) {
            if (form.querySelector("#contact_id").value.length == 0) {
                contactIdPresent = false
            } else {
                contactIdPresent = true
            }
        } else {
            contactIdPresent = true
        }
        Array.from(document.getElementById("validation-messages").children).map(function (element) {
            if (element.innerText.match(/First name.*/)) {
                element.classList.add("hidden");
            } else {
                if (element.innerText.match(/Last name.*/) || !contactIdPresent) {
                    element.innerText = "Payor Needs Selected"
                }
            }
        });
    }
}
