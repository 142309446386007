import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

let autoNumericList = [];

let stripe, card

export default class extends Controller {

    connect() {
        console.log("log from cr-otc-cc controller");
        autoNumericList = new AutoNumeric.multiple([".surcharge-display", ".total-charge"], ['NorthAmerican', {unformatOnSubmit: true}]);
    }

    getChargeForm(e) {
        e.preventDefault();
        console.log("get charge form from backend");
        const form = e.target.form;

        // Get the CSRF token from the meta tag
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        // Create a FormData object from the form
        const formData = new FormData(form);

        // Submit the form using Fetch API
        fetch(form.action, {
            method: form.method,
            body: formData,
            headers: {
                'X-CSRF-Token': csrfToken,
                'Accept': 'application/json',
            },
            credentials: 'same-origin',
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.errors || 'Unknown error occurred');
                    });
                }
                return response.json();
            })
            .then(data => {
                document.querySelector(".stripe-payment-form-area").innerHTML = data.html;
                document.querySelector(".card-detail-button").classList.add('hidden');
                e.target.closest(".cc-manual-fields").querySelector("#cr_cc_otc_keyed_payment_amount_tendered").disabled = true;
                stripe = Stripe(e.target.closest(".cc-manual-fields").querySelector("#stripe-api-key").value);
                var elements = stripe.elements();
                var style = {
                    base: {
                        color: "#32325d",
                        fontFamily: 'Poppins, sans-serif',
                        fontSize: '24px',
                        backgroundColor: '#f7fbfd'
                    }
                };

                card = elements.create("card", {style: style});
                card.mount(e.target.closest(".cc-manual-fields").querySelector("#card-element"));
                card.addEventListener('change', function (event) {
                    var displayError = document.getElementById('card-errors');
                    if (event.error) {
                        displayError.textContent = event.error.message;
                        document.getElementById('stripe-submit').disabled = true;
                    } else {
                        displayError.textContent = '';
                        document.getElementById('stripe-submit').disabled = false;
                    }
                });
            })
            .catch(error => {
                console.error('Error during form submission:', error);
                alert('Failed to set card payment. Please try again.');
            });

    }


    submitPayment(ev) {
        console.log("submitting to Stripe");
        ev.target.closest(".cc-manual-fields").querySelector("#stripe-submit").disabled = true;
        const clientSecret = ev.target.closest(".cc-manual-fields").querySelector('#stripe-key').value;
        const resultsArea = ev.target.closest(".cc-manual-fields").querySelector(".stripe-result")
        ev.preventDefault();
        stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: card,
            }
        }).then(function (result) {
            if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                console.log(result.error.message);
                resultsArea.innerHTML = "<p class=\"alert\">" + result.error.message + "</p>";
                ev.target.closest(".cc-manual-fields").querySelector("#stripe-submit").disabled = false;
            } else {
                // The payment has been processed!
                console.log("payment succeeded")
                if (result.paymentIntent.status === 'succeeded') {
                    resultsArea.innerHTML = "<p class=\"notice\"> Payment Successful</p>";
                    document.querySelector("#stripe-submit").classList.add('hidden');
                    ev.target.closest(".cc-manual-fields").querySelector(".stripe-payment-form-area").classList.add('hidden');
                    ev.target.closest(".cc-manual-fields").querySelector(".ready-to-pay").innerText = "true";
                    ev.target.closest(".cc-manual-fields").querySelector("#cr_cc_otc_keyed_payment_reference").value = result.paymentIntent.id;
                }
            }
        });
        return false;
    }

    updateChargeAmount(e) {
        console.log("Charge Amount updated, updating surcharge");
        const surchargeDisplay = e.target.closest(".cc-manual-fields").querySelector(".surcharge-display");
        const totalDisplay = e.target.closest(".cc-manual-fields").querySelector(".total-charge");
        const base = makeNumber(e.target.closest(".cc-manual-fields").querySelector("#cr_cc_otc_keyed_payment_surcharge_base").value);
        const rate = makeNumber(e.target.closest(".cc-manual-fields").querySelector("#cr_cc_otc_keyed_payment_surcharge_rate").value);
        const newAmountTendered = makeNumber(e.target.closest(".cc-manual-fields").querySelector("#cr_cc_otc_keyed_payment_amount_tendered").value);
        const newSurcharge = Math.round((newAmountTendered * (rate) + base) * 100) / 100;
        const newTotal = Math.round((newAmountTendered * (1 + rate) + base) * 100) / 100;
        surchargeDisplay.innerText = newSurcharge;
        totalDisplay.innerText = newTotal;
        autoNumericList.reformat;
    }

}

function makeNumber(textVal) {
    return Number(textVal.replace("$", "").replace(",", "").trim());
}